import React, { useEffect, useState } from 'react';

import './App.scss';
import Header from './Header';

import roasts from './roasts.json';

const App = () => {
  const [currentRoast, updateCurrentRoast] = useState('');

  useEffect(() => {
    generateRoast();
  }, []);

  const generateRoast = () => {
    const id = Math.floor(Math.random() * roasts.roasts.length);
    updateCurrentRoast(roasts.roasts[id]);
  };

  const roastMe = () => {
    const speech = new SpeechSynthesisUtterance(currentRoast);
    speech.lang = 'fr-FR';
    speechSynthesis.speak(speech);
  };

  return (
    <div className="App">
      <Header />
      <p>{currentRoast ? currentRoast : ''}</p>
      <span onClick={roastMe}>📢</span>
      <button onClick={() => generateRoast()}>Générer une nouvelle insulte</button>
      <audio controls loop>
        <source src={process.env.PUBLIC_URL + 'beat.wav'} type="audio/wav" />
        <source src={process.env.PUBLIC_URL + 'beat.mp3'} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default App;
