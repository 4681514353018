import React from 'react';
import './Header.scss';

const Header = () => {
  return (
    <header>
      <p>Gentle Roast</p>
    </header>
  );
}

export default Header;
